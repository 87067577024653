import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Danby Design : Home" keywords={[`gatsby`, `application`, `react`]} />
    <div>
    <h1>Danby Design</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    </div>

    <div>
    <h2>Header 2</h2>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    </div>

    <div>
    <h2>Header 2</h2>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    </div>
  </Layout>
)

export default IndexPage
